import React from 'react';
import ReactDOM from 'react-dom';
import Web3 from 'web3';
import { Web3ReactProvider } from '@web3-react/core';

import App from './App';
import * as serviceWorker from './serviceWorker';
import './index.css';
import 'react-toastify/dist/ReactToastify.css';

function getLibrary(provider) {
	const library = new Web3(provider);
	library.pollingInterval = 12000;
	return library;
}

// const stripePromise = loadStripe('pk_test_51KOsMyHsW9ET26JgqvWyTg2CSJPFBw3ojXuxR93kE8yUMQn7RpUsABQKRkVd1as8D8ldDPNaP2GaF0JoFtNNYKbs00zLAK2qyj');

ReactDOM.render(
	<React.StrictMode>
		<Web3ReactProvider getLibrary={getLibrary}>
			<App />
		</Web3ReactProvider>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
