import jwtDecode from "jwt-decode";

export const checkTokenVaildity = (access_token) => {
  if (!access_token) return false;
  const decoded = jwtDecode(access_token);

  const current_time = Date.now() / 1000;

  return decoded.exp > current_time;
};
