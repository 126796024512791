import ACTIONS from "./Action";

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.LIST_COLLECTIONS:
      return {
        ...state,
        list: action.data.data,
      };
    default:
      return state;
  }
};

export default reducer;
