import { createContext, useCallback, useReducer } from "react";
import appReduce from "./Reducer";
import * as Service from "./Services";
import Action from "./Action";

const initialState = {
  list: [],
};

export const CollectionContext = createContext(initialState);

export const CollectionContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(appReduce, initialState);

  const listCollections = useCallback(async (query) => {
    const res = await Service.listCollections(query);
    dispatch({ type: Action.LIST_COLLECTIONS, data: res });
    return res;
  }, []);

  const getCollectionById = useCallback(async (id) => {
    const res = await Service.getById(id);
    return res;
  }, []);

  const hideCollections = useCallback(async (id, payload) => {
    const res = await Service.hideCollections(id, payload);
    dispatch({ type: Action.HIDE_COLLECTION, data: res });
    return res;
  }, []);

  const featureCollection = useCallback(async (id, payload) => {
    const res = await Service.featureCollection(id, payload);
    dispatch({ type: Action.FEATURE_COLLECTION, data: res });
    return res;
  }, []);

  const featuredCollections = useCallback(async (query) => {
    const res = await Service.getFeaturedCollections(query);
    dispatch({ type: Action.GET_FEATURED_COLLECTIONS, data: res });
    return res;
  }, []);

  const getCollectionByGallery = useCallback(async (galleryId) => {
    const res = await Service.getByGallery(galleryId);
    dispatch({ type: Action.GET_COLLECTION_BY_GALLERY, data: res });
    return res;
  }, []);

  return (
    <CollectionContext.Provider
      value={{
        is_app_ready: state.is_app_ready,
        featureCollection,
        featuredCollections,
        hideCollections,
        listCollections,
        getCollectionById,
        getCollectionByGallery,
      }}
    >
      {children}
    </CollectionContext.Provider>
  );
};
