// import axios from "axios";
import api from "api";
import API from "../../constants/ApiConstant";
// import qs from "query-string";
import { getAccessToken } from "utils/sessionManager";
const access_token = getAccessToken();
const config = { headers: { Authorization: `Bearer ${access_token}` } };

export function listArtist(query) {
  return new Promise((resolve, reject) => {
    api
      .get(`${API.ARTISTS}/admin`, { ...config, params: query })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
}

export function hideArtist(id, payload) {
  return new Promise((resolve, reject) => {
    api
      .patch(`${API.ARTISTS}/admin/hide/${id}`, payload, config)
      .then((res) => resolve(res.data))
      .catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getById(id) {
  return new Promise((resolve, reject) => {
    api
      .get(`${API.ARTISTS}/${id}`)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response.data));
  });
}

export function featureArtist(id, payload) {
  return new Promise((resolve, reject) => {
    api
      .patch(`${API.ARTISTS}/admin/feature/${id}`, payload, config)
      .then((res) => resolve(res.data))
      .catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getFeaturedArtists(query) {
  return new Promise((resolve, reject) => {
    api
      .get(`${API.ARTISTS}/featured`, query)
      .then((res) => resolve(res.data))
      .catch((err) => {
        reject(err.response.data);
      });
  });
}
