const HOST_URL = process.env.REACT_APP_API_SERVER;
const API_BASE_URL = process.env.REACT_APP_API_SERVER + "/api/v1";
const IPFS_BASE_URL = process.env.REACT_APP_IPFS_URL || "https://ipfs.fleek.co";
const ASSET_URL = process.env.REACT_APP_API_SERVER + "/resources";
const ADMIN_APP_URL = process.env.REACT_APP_ADMIN_SERVER || "";
const APP_WEBSITE = process.env.REACT_APP_WEBSITE;

module.exports = {
  HOST_URL,
  API_BASE_URL,
  IPFS_BASE_URL,
  ASSET_URL,
  ADMIN_APP_URL,
  APP_WEBSITE: APP_WEBSITE + "/gallery/",
  ARTS: API_BASE_URL + "/arts",
  GALLERY: API_BASE_URL + "/gallery",
  COLLECTION: API_BASE_URL + "/collections",
  SOCIAL_USER: API_BASE_URL + "/social",
  USER_LOGIN: API_BASE_URL + "/auth",
  BUNDLE: API_BASE_URL + "/bundles",
  PAGE: `${API_BASE_URL}/pages`,
  SALES: `${API_BASE_URL}/nft/get_fiat_value`,
  ARTISTS: API_BASE_URL + "/artists",
  USERS: API_BASE_URL + "/users",
  TEMPLATES: API_BASE_URL + "/cmstemplates",
  PRICING_PLAN: API_BASE_URL + "/pricing",
};
