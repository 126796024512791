import React from "react";
import { Provider } from "react-redux";
import store from "./redux/store";
import { BrowserRouter as Router } from "react-router-dom";
import Views from "./views";
import { Route, Switch } from "react-router-dom";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { THEME_CONFIG } from "./configs/AppConfig";
import { ArtContextProvider } from "./modules/art/Context";
import { ToastContainer } from "react-toastify";
import { UserContextProvider } from "modules/user/Context";
import { ArtistContextProvider } from "modules/artist/Context";
import { CollectionContextProvider } from "modules/collection/Context";
import { AppContextProvider } from "modules/app/Context";

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <AppContextProvider>
          <CollectionContextProvider>
            <ArtContextProvider>
              <UserContextProvider>
                <ArtistContextProvider>
                  <ToastContainer position="bottom-right" />
                  <ThemeSwitcherProvider
                    themeMap={themes}
                    defaultTheme={THEME_CONFIG.currentTheme}
                    insertionPoint="styles-insertion-point"
                  >
                    <Router>
                      <Switch>
                        <Route path="/" component={Views} />
                      </Switch>
                    </Router>
                  </ThemeSwitcherProvider>
                </ArtistContextProvider>
              </UserContextProvider>
            </ArtContextProvider>
          </CollectionContextProvider>
        </AppContextProvider>
      </Provider>
    </div>
  );
}

export default App;
