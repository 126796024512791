import React, { useEffect } from "react";
import { Button } from "antd";
import { Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

const Logout = () => {
  const history = useHistory();
  const theme = useSelector((state) => state.theme.currentTheme);
  useEffect(() => {
    localStorage.clear();
    history.push("/admin/login");
  });
  return (
    <div className={`h-100 ${theme === "light" ? "bg-white" : ""}`}>
      <div className="container-fluid d-flex flex-column justify-content-between h-100 px-md-4 pb-md-4 pt-md-1">
        <div className="container">
          <div className="text-center mb-5">
            <h1 className="font-weight-bold mb-4">
              You are successfully logged out!!!
            </h1>
            <Link to="/admin/login">
              <Button type="primary">Login</Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Logout;
