import axios from "axios";
import qs from "query-string";
import { getAccessToken } from "utils/sessionManager";
const access_token = getAccessToken();

const api = axios.create({
  headers: {
    "Content-Type": "application/json",
    access_token,
    panel: "admin",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "brackets" });
  },
});
export default api;
