import React, { createContext, useReducer, useCallback } from "react";
import appReduce from "./Reducer";
import * as Service from "./Service";
import Action from "./Action";

const initialState = {
  list: [],
  pagination: { limit: 10, start: 0, total: 0, currentPage: 1, totalPages: 0 },
};

export const UserContext = createContext(initialState);
export const UserContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(appReduce, initialState);

  const listUser = useCallback(async (query) => {
    const res = await Service.listUser(query);
    dispatch({ type: Action.LIST_USER, data: res });
    return res;
  }, []);

  const hideUser = (id, payload) => {
    return Service.hideUser(id, payload);
  };

  const getUserById = (id) => {
    return Service.getUserById(id);
  };

  const approveUserForGallery = (id, payload) => {
    return Service.approveUser(id, payload);
  };

  const createGalleryUser = (payload) => {
    return Service.createGalleryUser(payload);
  };

  const updateUser = useCallback(async(id,payload)=>{
    const res = await Service.editUserInfo(id,payload);
    return res;
  },[])

  return (
    <UserContext.Provider
      value={{
        is_app_ready: state.is_app_ready,
        pagination: state.pagination,
        dispatch,
        listUser,
        hideUser,
        getUserById,
        approveUserForGallery,
        createGalleryUser,
        updateUser
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
