const TEST_SUB_DOMAIN = process.env.REACT_APP_TEST_SUBDOMAIN;
const CADDY_SSL_IP = process.env.REACT_APP_CADDY_SSL_IP;
const TINY_API_KEY = process.env.REACT_APP_TINY_API_KEY

module.exports = {
  SUB_DOMAIN: TEST_SUB_DOMAIN,
  LISTING_TYPES: { SALE: "Sale", TRIAL: "Trial" },
  APP: {
    FIAT_SYMBOL: "USD",
    IPFS_GATEWAY:
      process.env.REACT_APP_FLEEK_SERVER || "https://ipfs.fleek.co/ipfs",
  },
  PLANS: {
    FREE: "Free",
    STARTER: "Starter",
    BUSINESS: "Business",
    ENTERPRISE: "Enterprise",
  },
  ACCEPTED_FILES: {
    IMAGES: [".jpg, .png, .jpeg"],
    AUDIO: [".mp3"],
    VIDEO: [".mp4, .mpeg"],
    ZIP: [".zip"],
  },
  CADDY_SSL_IP,
  TINY_KEY:TINY_API_KEY
};
