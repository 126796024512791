import api from "api";
import API from "constants/ApiConstant";
// import qs from "query-string";
import { getAccessToken } from "utils/sessionManager";
const access_token = getAccessToken();
const config = { headers: { Authorization: `Bearer ${access_token}` } };

export const createCollection = (payload) => {
  return new Promise((resolve, reject) => {
    api
      .post(API.BUNDLE, payload)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

export const updateCollection = (id, payload) => {
  return new Promise((resolve, reject) => {
    api
      .put(`${API.BUNDLE}/${id}`, payload)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

export function listCollections(query) {
  return new Promise((resolve, reject) => {
    api
      .get(`${API.COLLECTION}/admin`, { ...config, params: query })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err?.response?.data);
      });
  });
}

export function getById(id) {
  return new Promise((resolve, reject) => {
    api
      .get(`${API.COLLECTION}/${id}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err?.response?.data);
      });
  });
}

export function featureCollection(id, payload) {
  return new Promise((resolve, reject) => {
    api
      .patch(`${API.COLLECTION}/admin/feature/${id}`, payload, config)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err?.response?.data);
      });
  });
}

export function getFeaturedCollections(query = {}) {
  return new Promise((resolve, reject) => {
    api
      .get(`${API.COLLECTION}/featured`, query)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err?.response?.data);
      });
  });
}

export function hideCollections(id, payload) {
  return new Promise((resolve, reject) => {
    api
      .patch(`${API.COLLECTION}/admin/hide/${id}`, payload, config)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err?.response?.data);
      });
  });
}

export function getByGallery(id) {
  return new Promise((resolve, reject) => {
    api
      .get(`${API.COLLECTION}/galleries/${id}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err?.response?.data);
      });
  });
}
