export const getCurrentUser = () => {
  let user = null;
  let data = localStorage.getItem("current_user");
  if (data) user = JSON.parse(data);
  return user;
};

export const saveCurrentUser = (userData) => {
  return localStorage.setItem("current_user", JSON.stringify(userData));
};

export const getAccessToken = () => {
  return localStorage.getItem("access_token");
};

export const saveAccessToken = (access_token) => {
  return localStorage.setItem("access_token", access_token);
};

// export const saveOptDetails = (optDetails) => {
//   return localStorage.setItem("opt_details", JSON.stringify(optDetails));
// };

// export const getOptDetails = () => {
//   let optDetails = null;
//   let data = localStorage.getItem("opt_details");
//   if (data) optDetails = JSON.parse(data);
//   return optDetails;
// };
