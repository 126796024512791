/* eslint-disable import/no-anonymous-default-export */
import ACTIONS from "./Action";

export default (state, action) => {
  switch (action.type) {
    case ACTIONS.LIST_ARTIST:
      return {
        ...state,
        list: action.data.data,
      };

    default:
      return state;
  }
};
