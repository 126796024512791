import React, { useEffect } from "react";
import { Route, Switch, Redirect, useHistory } from "react-router-dom";
import AppLayout from "layouts/app-layout";
import AuthLayout from "layouts/auth-layout";
import AppLocale from "lang";
import { IntlProvider } from "react-intl";
import { ConfigProvider } from "antd";
import { AUTH_PREFIX_PATH } from "configs/AppConfig";
import useBodyClass from "hooks/useBodyClass";
import Login from "../views/auth-views/authentication/verify";
import Logout from "../views/auth-views/authentication/logout";
import { toast } from "react-toastify";
import { checkTokenVaildity } from "utils/token";

const access_token = localStorage.getItem("access_token");
function RouteInterceptor({ children, ...rest }) {
  const history = useHistory();
  useEffect(() => {
    if (access_token) {
      const isValid = checkTokenVaildity(access_token);
      if (!isValid) {
        toast.error("Your session has expired");
        localStorage.clear();
        history.push("/admin/login");
      }
    }
  }, [access_token]);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        access_token ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: AUTH_PREFIX_PATH,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export const Views = (props) => {
  const { location } = props;
  const locale = "en";
  const direction = "ltr";
  const currentAppLocale = AppLocale[locale];
  useBodyClass(`dir-${direction}`);

  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}
    >
      <ConfigProvider locale={currentAppLocale.antd} direction={direction}>
        <Switch>
          <Route exact path="/admin/verify" component={Login} />
          <Route exact path="/admin/logout" component={Logout} />
          <Route exact path={AUTH_PREFIX_PATH}>
            <AuthLayout direction={direction} />
          </Route>
          <RouteInterceptor path="*">
            <AppLayout direction={direction} location={location} />
          </RouteInterceptor>
        </Switch>
      </ConfigProvider>
    </IntlProvider>
  );
};

export default Views;
