import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH } from "configs/AppConfig";

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route
          path={`${APP_PREFIX_PATH}/dashboard`}
          component={lazy(() => import(`./dashboards`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/pages`}
          component={lazy(() => import(`./pages`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/arts`}
          component={lazy(() => import(`./arts`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/users`}
          component={lazy(() => import(`./users`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/artists`}
          component={lazy(() => import(`./artists`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/profile`}
          component={lazy(() => import(`./pages/profile`))}
        />

        {/* <Route
          path={`${APP_PREFIX_PATH}/collection`}
          component={lazy(() => import(`./collection-arts`))}
        /> */}
        <Route
          path={`${APP_PREFIX_PATH}/cms/home-page`}
          component={lazy(() => import(`./pages/home-page`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/cms/youtube-page`}
          component={lazy(() => import(`./pages/youtube-page`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/cms/privacy`}
          component={lazy(() => import(`./pages/privacy`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/cms/terms`}
          component={lazy(() => import(`./pages/terms`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/cms/why-artswap`}
          component={lazy(() => import(`./pages/why-artswap`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/cms/about-us`}
          component={lazy(() => import(`./pages/about-us`))}
        />
        <Redirect from="*" to={`${APP_PREFIX_PATH}/dashboard`} />
      </Switch>
    </Suspense>
  );
};

export default React.memo(AppViews);
