import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { message } from 'antd';
import './verify.css';

import { saveAccessToken, saveCurrentUser } from 'utils/sessionManager';
import { AppContext } from 'modules/app/Context';
import { formatError } from 'utils';

const PROVIDERS = {
	GOOGLE: 'google'
};

export const LoginForm = () => {
	const { loginUsingGoogle, saveUserImage } = useContext(AppContext);
	const { search } = useLocation();

	useEffect(() => {
		async function login() {
			try {
				const id = new URLSearchParams(search).get('id');
				const email = new URLSearchParams(search).get('email');
				const d = { id, email };
				const { user, access_token } = await loginUsingGoogle({
					...d,
					service: PROVIDERS.GOOGLE
				});
				if (!user || !access_token) return message.error('User login failed. Pleas try again!');
				saveCurrentUser(user);
				saveAccessToken(access_token);
				saveUserImage(user.extras.profilePicUrl);
				window.location.replace('/admin/dashboard');
			} catch (err) {
				const errMsg = formatError(err);
				message.error(errMsg);
			}
		}
		login();
	}, [loginUsingGoogle, search, saveUserImage]);

	return (
		<>
			<div id="main">
				<div class="fof">
					<h1>We are verifying your identity</h1>
				</div>
			</div>
		</>
	);
};

export default LoginForm;
