import api from "../../api";
import API from "constants/ApiConstant";
import { getAccessToken } from "utils/sessionManager";
const access_token = getAccessToken();
const config = { headers: { Authorization: `Bearer ${access_token}` } };

export function getAppSettings() {
  return new Promise((resolve, reject) => {
    api
      .get(`${API.SETTINGS}/list`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
}

// =====CMS Templating======
export function saveTemplateAndData(payload) {
  return new Promise((resolve, reject) => {
    api
      .patch(`${API.TEMPLATES}`, payload, config)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getByPageName(name) {
  return new Promise((resolve, reject) => {
    api
      .get(`${API.TEMPLATES}/${name}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
}
