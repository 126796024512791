// import api from "api";
import API from "../../constants/ApiConstant";
import api from "api";
import { getAccessToken } from "utils/sessionManager";
const access_token = getAccessToken();
const config = { headers: { Authorization: `Bearer ${access_token}` } };

export function listArt(query) {
  return new Promise((resolve, reject) => {
    api
      .get(`${API.ARTS}/admin`, { ...config, params: query })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
}

export function addNft(payload) {
  return new Promise((resolve, reject) => {
    api
      .post(API.NFTS, payload)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
}

export function updateCollection(id, payload) {
  return new Promise((resolve, reject) => {
    api
      .put(`${API.NFTS}/${id}`, payload)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
}

export function uploadNft(payload) {
  return new Promise((resolve, reject) => {
    api
      .post(`${API.NFTS}/upload`, payload)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getTokenUri(payload) {
  return new Promise((resolve, reject) => {
    api
      .post(`${API.NFTS}/token-uri`, payload)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
}

export function updateNft(id, payload) {
  return new Promise((resolve, reject) => {
    api
      .put(`${API.NFTS}/${id}/minted`, payload)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getArtById(id) {
  return new Promise((resolve, reject) => {
    api
      .get(`${API.ARTS}/${id}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
}

export function listCategories(params) {
  return new Promise((resolve, reject) => {
    api
      .get(`${API.CATEGORY}`, { params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
}

export function hideArt(id, payload) {
  return new Promise((resolve, reject) => {
    api
      .patch(`${API.ARTS}/admin/hide/${id}`, payload, config)
      .then((res) => resolve(res.data))
      .catch((err) => {
        reject(err.response.data);
      });
  });
}

export function featureArt(id, payload) {
  return new Promise((resolve, reject) => {
    api
      .patch(`${API.ARTS}/admin/feature/${id}`, payload, config)
      .then((res) => resolve(res.data))
      .catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getFeaturedArts(params) {
  return new Promise((resolve, reject) => {
    api
      .get(`${API.ARTS}/featured`, { params })
      .then((res) => resolve(res.data))
      .catch((err) => {
        reject(err.response.data);
      });
  });
}
