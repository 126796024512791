import React, { createContext, useReducer, useCallback } from "react";
import appReduce from "./Reducer";
import * as Service from "./Service";
import Action from "./Action";

const initialState = {
  list: [],
};

export const ArtContext = createContext(initialState);
export const ArtContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(appReduce, initialState);

  const listArt = useCallback(async (query) => {
    const res = await Service.listArt(query);
    dispatch({ type: Action.LIST_ART, data: res });
    return res;
  }, []);

  const getArtById = useCallback((id) => {
    return Service.getArtById(id);
  }, []);

  const listCategories = useCallback((query) => {
    return Service.listCategories(query);
  }, []);

  const hideArt = (id, payload) => {
    return Service.hideArt(id, payload);
  };

  const featureArt = (id, payload) => {
    return Service.featureArt(id, payload);
  };

  const getFeaturedArts = useCallback((query) => {
    return Service.getFeaturedArts(query);
  }, []);

  return (
    <ArtContext.Provider
      value={{
        is_app_ready: state.is_app_ready,
        dispatch,
        listArt,
        getArtById,
        listCategories,
        hideArt,
        featureArt,
        getFeaturedArts,
      }}
    >
      {children}
    </ArtContext.Provider>
  );
};
